import intlTelInput from "intl-tel-input";

const openCalendly = function() {
    const name = this.dataset.name,
        email = this.dataset.email;

    const link = document.createElement('link');

    link.href = 'https://calendly.com/assets/external/widget.css';
    link.rel = 'stylesheet';

    document.head.appendChild(link);

    const script = document.createElement('script');

    script.src = 'https://calendly.com/assets/external/widget.js';
    script.type = 'text/javascript';

    script.addEventListener('load', (e) => {
        Calendly.initPopupWidget({
            url:'https://calendly.com/mm_info/gespraech',
            prefill: {
                name: name,
                email: email
            },
        });
    })

    window.addEventListener(
        'message',
        (e) => {

            if (e.data.event && 0 > e.data.event.indexOf('calendly') ) {
                return;
            }

            document.querySelector('.calendly-overlay').addEventListener('click', () => {
                script.remove();
                link.remove();
            });

        }
    );

    document.head.appendChild(script);
}

const initCalendly = () => {
    const calendlyElements = document.querySelectorAll('button.btn-arrange-appointment');

    for (let i = 0; calendlyElements.length > i; i++) {
        calendlyElements[i].addEventListener('click', openCalendly.bind(calendlyElements[i]))
    }
}

export default initCalendly;