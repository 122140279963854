const fitText = () => {

    const brand = document.querySelector('.fit-text');

    if (!brand) {
        return;
    }

    const parent = brand.parentElement,
    toggleIconWrapper = parent.querySelector('.toggle-icon-wrapper'),
    availableWidth = parent.clientWidth - toggleIconWrapper.clientWidth - window.getComputedStyle(toggleIconWrapper).marginRight.replace('px', ''),
    fontSize = window.getComputedStyle(brand).fontSize.replace('px', '');

    brand.style.fontSize = `${(fontSize * (availableWidth / brand.clientWidth))}px`;

}

export default fitText;