import React from 'react';
import { ResponsivePie } from '@nivo/pie'
import {BasicTooltip} from "@nivo/tooltip";

const ServicesChart = ({data}) => (
    <ResponsivePie
        data={data}
        margin={{ top: 40, right: 80, bottom: 40, left: 80 }}
        innerRadius={0.5}
        padAngle={2}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        colors={{ scheme: 'paired' }}
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
        arcLinkLabel={(e) => e.label.length > 15 ? `${e.label.substring(0, 15)}...` : e.label}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{ from: 'color', modifiers: [ [ 'darker', 2 ] ] }}
        onClick={ (node) => location.href = node.data.path }
        tooltip={({datum}) => <BasicTooltip id={datum.label} value={datum.value} color={datum.color} enableChip/>}
    />
)

export default ServicesChart;