import axios from 'axios';
import Sortable from 'sortablejs';

const initSortable = async () => {
    const sortableElements = document.querySelectorAll('.sortable'),
        configuration = {
            headers: {
                'Accept': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        };

    for (let i = 0; sortableElements.length > i; i++) {
        Sortable.create(sortableElements[i], {
            draggable: '.sortable-handle',
            direction: 'vertical',
            animation: 150,
            ghostClass: 'sortable-placeholder',

            onUpdate: async function (e) {

                const item = e.item,
                    itemId = item.dataset.id,
                    url = e.target.dataset.sortableTarget;

                if (itemId === undefined || url === undefined) {
                    return;
                }

                const data = {
                    sorted_id: itemId,
                    index: e.newIndex + 1
                };

                await axios.post(url, data, configuration);
            }
        });
    }
}

export default initSortable;