// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require('trix')
require('@rails/actiontext')

import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from '@rails/activestorage';

import navbarTopDropShadow from '../theme/navbar-top';
import handleNavbarVerticalCollapsed from '../theme/navbar-vertical';
import tooltipInit from '../theme/tooltip';
import initModal from '../scripts/modal';
import initSortable from '../scripts/sortable';
import initPhone from '../scripts/phone';
import fitText from '../scripts/fit-text';
import initCalendly from '../scripts/calendly';

Rails.start();
Turbolinks.start();
ActiveStorage.start();

const ReactRailsUJS = require('react_ujs');
const componentRequireContext = require.context('components', true);

ReactRailsUJS.useContext(componentRequireContext);

document.addEventListener('turbolinks:load', handleNavbarVerticalCollapsed);
document.addEventListener('turbolinks:load', navbarTopDropShadow);
document.addEventListener('turbolinks:load', tooltipInit);
document.addEventListener('turbolinks:load', initModal);
document.addEventListener('turbolinks:load', initSortable);
document.addEventListener('turbolinks:load', initPhone);
document.addEventListener('turbolinks:load', fitText);
document.addEventListener('turbolinks:load', initCalendly);