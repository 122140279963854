import React from 'react';
import PropTypes from 'prop-types';
import FsLightbox from 'fslightbox-react';
import I18n from 'i18n-js';

export default class Lightbox extends React.PureComponent  {

    constructor(props) {
        super(props);

        this.state = {
            active: false
        };

        this.file_extension = this.props.source.split('.').pop().toLowerCase();
        this.svg = {
            toolbarButtons: {
                zoomIn: {
                    title: I18n.t('lightbox.zoom_in')
                },
                zoomOut: {
                    title: I18n.t('lightbox.zoom_out')
                },
                close: {
                    title: I18n.t('lightbox.close')
                },
                fullscreen: {
                    enter: {
                        title: I18n.t('lightbox.fullscreen.enter')
                    },
                    exit: {
                        title: I18n.t('lightbox.fullscreen.exit')
                    }
                },
            }
        };

        if(this.file_extension === 'pdf' ) {
            this.source = [<iframe src={this.props.source} width={window.screen.width} height={window.screen.height}/>]
            this.className = 'fslightbox-pdf';
        } else {
            this.source = [this.props.source];
            this.className = 'fslightbox-image';
        }
    }

    componentDidMount() {
        this.trigger = document.querySelector(this.props.trigger)
        this.trigger.addEventListener('click', this.onClick)
    }

    componentWillUnmount() {
        this.trigger.removeEventListener('click', this.onClick);
    }

    onClick = (e) => {
        e.preventDefault();

        this.setState({active: !this.state.active});
    }

    render() {
        return (
            <div className={this.className}>
                <FsLightbox toggler={this.state.active}
                            exitFullscreenOnClose={true}
                            sources={this.source}
                            zoomIncrement={0.5}  />
            </div>
        )
    }
}

Lightbox.propTypes = {
    source: PropTypes.string.isRequired,
    trigger: PropTypes.string.isRequired
}