import React from 'react';
import PropTypes from 'prop-types';

class FileInputArea extends React.PureComponent  {

    fileInputChanged = () => {
        this.fileInput.form.submit();
    }

    render() {

        return (
            <div onClick={() => this.fileInput.click()} className='rounded-3 w-100 d-flex justify-content-center align-items-center mt-2' style={{backgroundColor: this.props.backgroundColor, minHeight: '150px', cursor: 'pointer'}}>
                <div className='fa-3x'>
                    <i className={`fas fa-${this.props.icon}`} />
                </div>
                <input type='file' name={this.props.name} accept='image/*' ref={el => this.fileInput = el} style={{display: 'none'}} onChange={this.fileInputChanged} />
            </div>
        )

    }

}

FileInputArea.propTypes = {
    name: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired
}

FileInputArea.defaultProps = {
    name: 'file',
    icon: 'image',
    backgroundColor: '#EEEEEE'
}

export default FileInputArea;