import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/js/utils';

const initPhone = () => {
    const phoneElements = document.querySelectorAll('input[type="tel"]');

    for (let i = 0; phoneElements.length > i; i++) {
        let options = {
            autoPlaceholder: 'off',
            preferredCountries: ['de', 'at', 'ch'],
            onlyCountries: ['al', 'ad', 'at', 'by', 'be', 'ba', 'bg', 'hr', 'cz', 'dk',
                'ee', 'fo', 'fi', 'fr', 'de', 'gi', 'gr', 'va', 'hu', 'is', 'ie', 'it', 'lv',
                'li', 'lt', 'lu', 'mk', 'mt', 'md', 'mc', 'me', 'nl', 'no', 'pl', 'pt', 'ro',
                'ru', 'sm', 'rs', 'sk', 'si', 'es', 'se', 'ch', 'ua', 'gb']
        };

        if(phoneElements[i].dataset.fieldName) {
            options['hiddenInput'] = phoneElements[i].dataset.fieldName;
        }

        intlTelInput(phoneElements[i], options);
    }
}

export default initPhone;