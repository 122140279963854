import React from 'react';
import Calendar from './Calendar';
import TimePicker from './TimePicker';
import PropTypes from 'prop-types';

class DateTimePicker extends React.PureComponent {

    constructor(props) {

        super(props);

        const date = this.props.date;
        date.setSeconds(0, 0);

        this.state = {
            date: date,
            displayTime: this.props.displayTime
        }

    }

    componentDidUpdate(_prevProps, prevState) {

        if(this.state.date === prevState.date) {
            return;
        }

        if(this.props.onChange) {
            this.props.onChange(this.state.date);
        }

    }

    onChange = ({year, month, date, hours, minutes}) => {

        const newDate = new Date(this.state.date.getTime());

        if(!year) {
            newDate.setHours(hours, minutes, 0, 0);
        } else {
            newDate.setFullYear(year, month, date);
        }

        this.setState({date: newDate});

    }

    onModeChange = (mode) => {

        if(mode === 'date') {
            this.setState({displayTime: true});
        } else {
            this.setState({displayTime: false});
        }

    }

    render() {

        return (

            <div className='date-time-picker'>
                <Calendar locale={this.props.locale} date={this.state.date} onChange={this.onChange} onModeChange={this.onModeChange} />
                { this.state.displayTime && this.props.displayTime && <TimePicker locale={this.props.locale} date={this.state.date} onChange={this.onChange} /> }
                { this.props.onSave && <button type='button' className='save' onClick={() => this.props.onSave(this.state.date)} tabIndex='3'>{this.props.saveLabel}</button> }
                { this.props.onReset && <button type='button' className='reset' onClick={() => this.props.onReset()} tabIndex='4'>{this.props.resetLabel}</button> }
            </div>

        )

    }

}

DateTimePicker.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
    locale: PropTypes.string.isRequired,
    displayTime: PropTypes.bool.isRequired,
    saveLabel: PropTypes.string.isRequired,
    resetLabel: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onSave: PropTypes.func,
    onReset: PropTypes.func
}

DateTimePicker.defaultProps = {
    date: new Date(),
    locale: document.documentElement.lang,
    displayTime: true,
    resetLabel: 'Leer'
}

export default DateTimePicker;