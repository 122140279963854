import { Modal } from 'bootstrap';

const showModal = (e) => {

    e.preventDefault();

    const link = e.target;

    if(!link.href) {
        return;
    }

    const title = link.title,
          labelClose = link.dataset.labelClose || 'Close';

    let modalHeader = '';

    if(title) {
        modalHeader = `<div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                            <h4 class="mb-1" id="modalExampleDemoLabel">${title}</h4>
                        </div>`;
    }

    const modalContainer = document.createElement('div');

    modalContainer.classList.add('modal');
    modalContainer.classList.add('fade');
    modalContainer.tabindex = -1;
    modalContainer.role = 'dialog';
    modalContainer.ariaHidden = true;

    modalContainer.innerHTML = `<div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                                    <div class="modal-content position-relative">
                                        <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                                            <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal" aria-label="${labelClose}"></button>
                                        </div>
                                        <div class="modal-body p-0">
                                            ${modalHeader}
                                            <div class="iframe-modal-body"></div>
                                            <div class="modal-footer">
                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">${labelClose}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>`;

    document.body.append(modalContainer);

    let iframe = document.createElement('iframe');

    iframe.onload = () => {
        setTimeout(() => {
            const body = iframe.contentWindow.document.body,
                html = iframe.contentWindow.document.documentElement;

            window.postMessage({height: Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight)}, '*');
        }, 250)
    }

    iframe.src = link.href

    modalContainer.querySelector('.iframe-modal-body').appendChild(iframe);

    let modal = new Modal(modalContainer);

    modalContainer.addEventListener('hidden.bs.modal', () => {
        modal.dispose();
        modalContainer.remove();
    });

    modal.show();
}

const initModal = () => {
    if(window.self !== window.parent) {
        return;
    }

    window.addEventListener('message', (e) => {

        if(!Object.prototype.hasOwnProperty.call(e.data, 'height')) {
            return;
        }

        const iframe = document.querySelector('iframe');

        if(iframe) {
            iframe.height = e.data.height;
        }

        console.log(e.data);
    });

    const elements = document.querySelectorAll('[data-toggle="modal"]');

    for(let i=0; elements.length>i; i++) {
        elements[i].addEventListener('click', showModal);
    }
}

export default initModal;