import React from 'react';
import PropTypes from 'prop-types';

class CalendarDate extends React.PureComponent {

    render() {

        const classes = ['date'];

        if(this.props.date.getMonth() < this.props.calendarMonth) {
            classes.push('previous-month');
        } else if (this.props.date.getMonth() > this.props.calendarMonth) {
            classes.push('next-month');
        } else {
            classes.push('current-month');
        }

        const day = this.props.date.getDay()

        if(day === 0 || day === 6) {
            classes.push('weekend');
        }

        if(this.props.date.toDateString() === new Date().toDateString()) {
            classes.push('today');
        }

        if(this.props.selected) {
            classes.push('selected');
        }

        return <div className={classes.join(' ')} onClick={() => this.props.onClick(this.props.date)}>{ this.props.date.getDate() }</div>;

    }

}

CalendarDate.propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
    calendarMonth: PropTypes.number.isRequired,
    selected: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
}

class YearView extends React.PureComponent {

    constructor(props) {

        super(props);

        this.state = {
            years: [],
            year: this.props.year
        }

    }

    componentDidMount() {
        this.updateYears();
    }

    componentDidUpdate(prevProps, prevState) {

        if(this.state.year !== prevState.year) {
            this.updateYears();
        }

    }

    updateYears = () => {

        const years = [];

        for(let operand = -12; operand <= 12; operand++) {
            years.push(this.state.year + operand);
        }

        this.setState({years: years});

    }

    navigate = (step) => {
        this.setState({year: this.state.year + step});
    }

    render() {

        return (
            <React.Fragment>
                <div className='navigation'>
                    <button type='button' className='navigate-previous' onClick={() => this.navigate(-25)} />
                    <div className='month'>
                        <span className='selected-year' onClick={() => this.props.setMode('date')}>
                            { this.state.years[0] } - { this.state.years[24] }
                        </span>
                    </div>
                    <button type='button' className='navigate-next' onClick={() => this.navigate(25)} />
                </div>
                <div className='years'>
                    {
                        this.state.years.map((year, idx) => {

                            let classes = 'year';

                            if(year === this.props.year) {
                                classes = `${classes} current-year`;
                            }

                            return <div key={idx} className={classes} onClick={() => this.props.onClick(year)}>{year}</div>

                        })
                    }
                </div>
            </React.Fragment>
        );

    }

}

YearView.propTypes = {
    locale: PropTypes.string.isRequired,
    year: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    setMode: PropTypes.func.isRequired
}

class MonthView extends React.PureComponent {

    constructor(props) {

        super(props);

        this.months = []

        const formatter = new Intl.DateTimeFormat(this.props.locale, { month: 'long' })

        for(let month = 0; month < 12; month++) {
            this.months.push(formatter.format(new Date(Date.UTC(1970, month, 1, 0, 0, 0, 0))));
        }

    }

    render() {

        return (
            <React.Fragment>
                <div className='navigation'>
                    <div className='month'>
                        <span className='selected-year' onClick={() => this.props.setMode('date')}>
                            { this.months[0] } - { this.months[11] }
                        </span>
                    </div>
                </div>
                <div className='months'>
                    {
                        this.months.map((month, idx) => {

                            let classes = 'month';

                            if(month === this.months[this.props.month]) {
                                classes = `${classes} current-month`;
                            }

                            return <div key={idx} className={classes} onClick={() => this.props.onClick(idx)}>{month}</div>;

                        })
                    }
                </div>
            </React.Fragment>
        );

    }

}

MonthView.propTypes = {
    locale: PropTypes.string.isRequired,
    month: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    setMode: PropTypes.func.isRequired
}

class DateView extends React.PureComponent {

    constructor(props) {

        super(props);

        this.state = {
            year: this.props.year,
            month: this.props.month,
            date: this.props.date
        }

    }

    days = () => {

        const days = [];

        const formatter = new Intl.DateTimeFormat(this.props.locale, {weekday: 'narrow'});

        for(let day = 1; day <= 7; day++) {
            days.push(formatter.format(new Date(Date.UTC(1970, 5, day, 0, 0, 0, 0))));
        }

        return days;

    }

    firstDayInMonth = () => {
        return new Date(this.state.year, this.state.month, 1).getDay();
    }

    lastDateInMonth = (month) => {
        return new Date(this.state.year, month + 1, 0).getDate();
    }

    calendarDates = () => {

        const startDate = (this.firstDayInMonth() - 2) * -1;
        const endDate = 42 + startDate;

        const dates = []

        for(let date=startDate; date < endDate; date++) {
            dates.push(new Date(this.state.year, this.state.month, date));
        }

        return dates;

    }

    navigate = (step) => {

        const newMonth = this.state.month + step;
        const date = new Date(this.state.year, newMonth, 1);
        const lastDate = this.lastDateInMonth(newMonth);

        if(lastDate < this.state.date) {
            this.setState({year: date.getFullYear(), month: date.getMonth(), date: lastDate});
        } else if(this.lastDateInMonth(this.state.month) === this.state.date) {
            this.setState({year: date.getFullYear(), month: date.getMonth(), date: lastDate});
        } else {
            this.setState({year: date.getFullYear(), month: date.getMonth()});
        }

    }

    onClick = (date) => {

        this.setState({ year: date.getFullYear(), month: date.getMonth(), date: date.getDate() });

        this.props.onClick(date);

    }

    render() {

        const currentDate = new Date(this.state.year, this.state.month, this.state.date);

        return (
            <React.Fragment>
                <div className='navigation'>
                    <button type='button' className='navigate-previous' onClick={() => this.navigate(-1)} />
                    <div className='month'>
                        <span className='selected-month' onClick={() => this.props.setMode('month')}>
                            { currentDate.toLocaleString(this.props.locale, {month: 'long'}) }
                        </span>
                        <span className='selected-year' onClick={() => this.props.setMode('year')}>
                            { currentDate.toLocaleString(this.props.locale, {year: 'numeric'}) }
                        </span>
                    </div>
                    <button type='button' className='navigate-next' onClick={() => this.navigate(1)} />
                </div>
                <div className='days'>
                    { this.days().map((day, idx) => <div key={idx} className='day'>{day}</div>) }
                </div>
                <div className='dates'>
                    {

                        this.calendarDates().map((date, idx) => {
                            return <CalendarDate key={idx} calendarMonth={this.state.month} selected={currentDate.toDateString() === date.toDateString()} date={date} onClick={this.onClick} />;
                        })

                    }
                </div>
            </React.Fragment>
        )

    }

}

DateView.propTypes = {
    locale: PropTypes.string.isRequired,
    year: PropTypes.number.isRequired,
    month: PropTypes.number.isRequired,
    date: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
    setMode: PropTypes.func.isRequired
}

class Calendar extends React.PureComponent {

    constructor(props) {

        super(props);

        this.state = Object.assign(this.dateToState(this.props.date), {mode: 'date'});

    }

    componentDidUpdate(_prevProps, prevState) {

        if(this.state.mode !== prevState.mode && this.props.onModeChange) {
            this.props.onModeChange(this.state.mode);
        }

        if(this.state.year === prevState.year && this.state.month === prevState.month && this.state.date === prevState.date) {
            return;
        }

        if(this.props.onChange)  {
            this.props.onChange(this.state);
        }

    }

    dateToState = (date) => {

        return {
            date: date.getDate(),
            month: date.getMonth(),
            year: date.getFullYear()
        }

    }

    setYear = (year) => {
        this.setState({year: year, mode: 'date'});
    }

    setMonth = (month) => {
        this.setState({month: month, mode: 'date'});
    }

    setDate = (date) => {
        this.setState(this.dateToState(date));
    }

    setMode = (mode) => {

        if(mode === this.state.mode) {
            this.setState({mode: 'date'});
        } else {
            this.setState({mode: mode});
        }

    }

    render() {

        let view;

        if(this.state.mode === 'date') {
            view = <DateView locale={this.props.locale} year={this.state.year} month={this.state.month} date={this.state.date} onClick={this.setDate} setMode={this.setMode} />;
        } else if(this.state.mode === 'month') {
            view = <MonthView locale={this.props.locale} month={this.state.month} onClick={this.setMonth} setMode={this.setMode} />;
        } else if(this.state.mode === 'year') {
            view = <YearView locale={this.props.locale} year={this.state.year} onClick={this.setYear} setMode={this.setMode} />
        } else {
            view = null;
        }

        return (

            <div className='calendar'>
                {view}
            </div>

        )

    }

}

Calendar.propTypes = {
    locale: PropTypes.string.isRequired,
    date: PropTypes.instanceOf(Date).isRequired,
    onChange: PropTypes.func,
    onModeChange: PropTypes.func
}

Calendar.defaultProps = {
    locale: document.documentElement.lang,
    date: new Date()
}

export default Calendar;