import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

export default class DragDropUploader extends React.PureComponent {

    eventTargets = [];

    state = {
        icon: 'upload',
        mode: 'click'
    }

    componentDidMount() {

        window.addEventListener('file-dropped', this.onFileDropped);

        window.addEventListener('dragenter', this.onDragEnter);
        window.addEventListener('dragleave', this.onDragLeave);

        this.container = this.trigger.closest('.card');
        this.overlay = this.container.querySelector('.drop-overlay');

        this.overlay.addEventListener('dragover', this.onDragOver);
        this.overlay.addEventListener('drop', this.onDrop);

    }

    componentWillUnmount() {

        window.removeEventListener('file-dropped', this.onFileDropped);

        window.removeEventListener('dragenter', this.onDragEnter);
        window.removeEventListener('dragleave', this.onDragLeave);

        this.overlay.removeEventListener('dragover', this.onDragOver);
        this.overlay.removeEventListener('drop', this.onDrop);

    }

    onDragEnter = e => {

        this.eventTargets.push(e.target);

        if(this.eventTargets.length === 1) {
            this.container.classList.add('drop-file');
            this.setState({mode: 'drag'});
        }

    }

    onDragOver = e => {

        e.preventDefault();

    }

    onDrop = async e => {

        e.preventDefault();

        window.dispatchEvent(new CustomEvent('file-dropped'));

        await this.uploadFiles(e.dataTransfer.files);

    }

    onDragLeave = () => {

        this.eventTargets.pop();

        if(this.eventTargets.length === 0) {
            this.container.classList.remove('drop-file');
            this.setState({mode: 'click'});
        }

    }

    onFileDropped = () => {
        this.eventTargets = [];
        this.container.classList.remove('drop-file');
        this.setState({mode: 'click'});
    }

    uploadFiles = async (files) => {

        this.toggleIcon()

        for(const file of files) {

            const formData = new FormData();

            formData.append('document[title]', file.name)
            formData.append('document[file]', file);

            try {

                await axios.post(this.props.url, formData, {
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'Content-Type': 'multipart/form-data',
                        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
                    }
                });

            } catch(e) {
                alert(`${this.props.translations.uploadFailed}: ${file.name}`);
            }

        }

        this.toggleIcon()

        location.reload();

    }

    toggleIcon = () => {
        this.setState({icon: this.state.icon === 'upload' ? 'sync fa-spin' : 'upload'});
    }

    onClickUpload = () => {
        this.fileInput.click();
    }

    onFileInputChange = async e => {
        await this.uploadFiles(e.target.files);
    }

    render() {

        if(this.state.mode === 'drag') {
            return null;
        }

        return (
            <React.Fragment>
                <button ref={el => this.trigger = el} data-bs-toggle='tooltip' title={this.props.translations.title} className='btn btn-link text-600 btn-sm' onClick={this.onClickUpload}>
                    <i className={`fas fa-${this.state.icon}`} />
                </button>
                <input ref={el => this.fileInput = el} type='file' multiple={true} style={{display: 'none'}} onChange={this.onFileInputChange} />
            </React.Fragment>
        );
    }

}

DragDropUploader.propTypes = {
    url: PropTypes.string.isRequired,
    translations: PropTypes.shape({
        title: PropTypes.string.isRequired,
        uploadFailed: PropTypes.string.isRequired
    }).isRequired
}
